import React from 'react';
import TABLE1 from './Table';
import TABLE2 from './Table';
import TsTarget from './TsTarget';


let lastCol = 0;

export default class TsCampaign extends React.Component {
  constructor() {

    super();
    this.state = {
      tsCampaignData: [],
      tsCampaignLink: "",
      tsCampaignDisplay: "",
      tsCampaignDataTableRows: [],
      tsCampaignDataTableColumns: [],
      index: 0
    }

  }
  componentDidMount() {
    this.setState({
    });
  }

  render() {
    var ro = [];
    ro.push(this.props.tsCampaignDataFull.rows[this.props.counter]);
    delete ro[0].childrenTargets;
    delete ro[0].childrenBanners;
    var bannerRows = [];
    for (let i = 0; i < this.props.tsCampaignDataBanner.rows.length; i++) {
      bannerRows.push(this.props.tsCampaignDataBanner.rows[i]);
    }

    console.log(this.props.tsCampaignDataFull);

    lastCol = 0;
    var colObj = null;
    for (let i = 0; i < 1; i++) {
      colObj = {};
      for (let k = 0; k < this.props.tsCampaignDataBanner.totals.length; k++) {
        colObj[k] = this.props.tsCampaignDataBanner.totals[k];
        lastCol = k;
      }
      colObj[lastCol + 1] = "Count Row";
      bannerRows.push(this.props.tsCampaignDataBanner.totals);
    }

    return (
      <div style={styleDiv}>
        <div style={stylePadd}>
          <a href={this.state.tsCampaignLink}>{this.state.tsCampaignDisplay}</a>
          <div>
            <TABLE1 columns={this.props.tsCampaignDataFull.columns} results={ro} />
          </div>
          {this.props.tsCampaignDataTargets.rows.map((result, index) => {
            return <div key={index}>
              <p>Targets:</p>
              <TsTarget tsTargetData={result} index={index}
                tsTargetFull={this.props.tsCampaignDataTargets}
                tsTargetDailyStat={this.props.tsCampaignDataTargets.rows[index].childrenDailyStats}
              />
            </div>
          })}
          <p>Banners:</p>
          <div >
            <TABLE2 columns={this.props.tsCampaignDataBanner.columns} lastCol={lastCol} results={bannerRows} />
          </div>
        </div>
      </div>
    )
  }

}

const styleDiv = {
  backgroundColor: "#f2f2f2",
  marginLeft: 20,
  //marginRight:20,
  marginTop: 20,
}

const stylePadd = {
  padding: 20
}

