
// import SIDEBAR from '../Sidebars';
// import Toolbar from 'material-ui/Toolbar';
// import { FaPowerOff } from 'react-icons/fa';
// import ReactTooltip from 'react-tooltip';
import React from 'react';
import IncidentAction, { IIncidentAction } from './IncidentAction';
import Firebase from '../Firebase';
// import utils from '../utils';
import '../App.css';
// import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
// import AppBar from 'material-ui/AppBar';
// import TextField from 'material-ui/TextField';
// import Logo from '../logo_app.png';
import RaisedButton from 'material-ui/RaisedButton';
import {Container, Row, Col} from 'react-bootstrap';
import Loader from '../loaderImg.gif';
import BASE_URL from '../constants';
import {
  getInfoAbout_TrackerCampaign,
  getInfoAbout_TS,
  getInfoAbout_TsBanner,
  getInfoAbout_TsCampaign,
  getInfoAbout_TsTarget,
  getInfoAbout_TsZone,
  IInfoAboutPerformanceData
} from 'src/models/infoAboutPerformanceData';
import { CSSProperties } from 'react';


export enum CLASS_TYPE {

  TRACKER = "1",
  TS = "T",
  TRACKER_CAMPAIGN = "K",
  TS_CAMPAIGN = "C",
  TS_BANNER = "B",
  TS_TARGET = "G",
  TS_ZONE = "Z",

  tsBannerList = 'tsBannerList',
  tsTargetList = 'tsTargetList',
  tsZoneList = 'tsZoneList',
}


export type ITs_IdOption = {
  tsId: string,
}
export type ITrackerCampaign_IdOption = {
  tsId: string,
  trackerCampaignId: string,
}
export type ITsCampaign_IdOption = {
  tsId: string,
  trackerCampaignId: string,
  tsCampaignId: string,
}
export type ITsBanner_IdOption = {
  tsId: string,
  trackerCampaignId: string,
  tsCampaignId: string,
  tsBannerId: string,
}
export type ITsTarget_IdOption = {
  tsId: string,
  trackerCampaignId: string,
  tsCampaignId: string,
  tsTargetId: string,
}
export type ITsZone_IdOption = {
  tsId: string,
  trackerCampaignId: string,
  tsCampaignId: string,
  tsZoneId: string,
}
export type IPossibleIdOptions = ITs_IdOption | ITrackerCampaign_IdOption | ITsCampaign_IdOption
  | ITsBanner_IdOption | ITsTarget_IdOption | ITsZone_IdOption;

interface IValueSnapshot {
  [field: string]: any,
}

export interface ISAMI_Incident {
  _id: string,
  objectIDs: IPossibleIdOptions;
  targetType: CLASS_TYPE;

  ruleId: string;
  ruleVersion: number;
  ruleDecription: string;
  val: IValueSnapshot;

  wasArchived: null;

  actions: {
    [actionId: string]: IIncidentAction,
  };

  createdAt: Date;
  updatedAt: Date;
}

interface ICurrentState {
  isLoading: boolean,
  hasError: boolean,
}

interface ICurrentProps {
  incident: ISAMI_Incident,
  updateArchivedincidents: (incidentIDs: string[]) => void,
  infoAboutPerformanceData: IInfoAboutPerformanceData,
}

function readableDate(date: Date) {
  return new Date(date).toISOString().replace("T"," ").substr(0,19);
}

export default class Incident extends React.Component<ICurrentProps, ICurrentState> {

  constructor(props: ICurrentProps) {
    super(props)

    this.state = {
      isLoading: false,
      hasError: false,
    };
  }

  archiveIncidentList(incidentIDs: string[]) {

    this.setState({
      isLoading: true,
      hasError: true,
    });

    const incidentList = Array.isArray(incidentIDs) ? incidentIDs : [incidentIDs];

    return Firebase.getUserToken().then(token => {
      return fetch(BASE_URL + 'sami/archiveIncidents', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          incidentIdListToArchive: incidentList,
        }),
      }).then((response) => {
        if (response.status !== 200) {
          throw new Error(`Server responded with error response code (${response.status}/${response.statusText})`);
        }
        return response.json();
      }).then((responseJson) => {
        if (responseJson.error) {
          this.setState({
            isLoading: false,
            hasError: true,
          });
          const errorStr = "An error occurred!\nReason: " + responseJson.error;
          console.error(errorStr);
          throw new Error(errorStr);
        } else {
          this.setState({
            isLoading: false,
            hasError: false,
          });
          this.props.updateArchivedincidents(incidentIDs);
        }
      }).catch((error) => {
        console.error(`An error occurred!\nReason:\n${error.stack}`);
        // alert(`Error has occurred!`);
        this.setState({
          isLoading: false,
          hasError: true,
        });
      });
    });
  }

  openLinkInNewTab(URL: string) {
    window.open(URL, '_blank');
  }

  render() {
    const info = this.props.infoAboutPerformanceData;

    const tsId: string = this.props.incident.objectIDs.tsId;
    const trackerCampaignId: string = (this.props.incident.objectIDs as ITrackerCampaign_IdOption).trackerCampaignId;
    const tsCampaignId: string = (this.props.incident.objectIDs as ITsCampaign_IdOption).tsCampaignId;
    const tsBannerId: string = (this.props.incident.objectIDs as ITsBanner_IdOption).tsBannerId;
    const tsTargetId: string = (this.props.incident.objectIDs as ITsTarget_IdOption).tsTargetId;
    const tsZoneId: string = (this.props.incident.objectIDs as ITsZone_IdOption).tsZoneId;

    let fourthId: JSX.Element = <></>;
    let parsedTargetType: string = '';
    let linkToDashboard = '';
    let backgroundColor = 'white';
    switch (this.props.incident.targetType) {
      case CLASS_TYPE.TS_CAMPAIGN:
        // fourthId = ``;
        parsedTargetType = 'TS Campaign';
        linkToDashboard = `#/4_special2/${tsId}/${trackerCampaignId}/${tsCampaignId}`;
        backgroundColor = 'lightcyan';
        break;
      case CLASS_TYPE.TS_BANNER:
        fourthId = <span>TS Banner: <b>{(getInfoAbout_TsBanner(info, tsId, trackerCampaignId, tsCampaignId, tsBannerId)).n}</b> ({tsBannerId})</span>;
        parsedTargetType = 'TS Banner';
        linkToDashboard = `#/4_special2/${tsId}/${trackerCampaignId}/${tsCampaignId}`;
        backgroundColor = 'lightskyblue';
        break;
      case CLASS_TYPE.TS_TARGET:
        fourthId = <span>TS Target: <b>{(getInfoAbout_TsTarget(info, tsId, trackerCampaignId, tsCampaignId, tsTargetId)).n}</b> ({tsTargetId})</span>;
        parsedTargetType = 'TS Target';
        linkToDashboard = `#/3_special/${tsId}/${trackerCampaignId}`;
        backgroundColor = 'lightblue';
        break;
      case CLASS_TYPE.TS_ZONE:
        fourthId = <span>TS Zone: <b>{(getInfoAbout_TsZone(info, tsId, trackerCampaignId, tsCampaignId, tsZoneId)).n}</b> ({tsZoneId})</span>;
        parsedTargetType = 'TS Zone';
        linkToDashboard = `https://admin.exoclick.com/panel/advertiser/statistics/zone?campaignID=${tsCampaignId}&zoneID=${tsZoneId}`;
        backgroundColor = 'powderblue';
        break;
      default:
        // fourthId = '';
        linkToDashboard = ``;
        backgroundColor = 'lightcoral';
        break;
    }

    if (this.state.isLoading) {
      return <img src={Loader} style={styleLoaderImg} alt="loader" />;
    }

    return (
      <div style={{
        border: 'solid black 1px',
        backgroundColor: backgroundColor,
        padding: '5px',
      }}>
        <br />
        <h6>Incident (#{this.props.incident._id}) about {parsedTargetType}</h6>
        <div>{this.props.incident.ruleDecription}</div>
        <br />
        <Container>
          <Row>
            <Col style={{ textAlign: 'start', borderRight: 'black solid 1px' }}>
              <u><b>Relevant IDs:</b></u><br />
              Traffic Source: <b>{(getInfoAbout_TS(info, tsId)).n}</b> ({tsId})<br />
              {'trackerCampaignId' in this.props.incident.objectIDs ? <span>Tracker Campaign: <b>{(getInfoAbout_TrackerCampaign(info, tsId, trackerCampaignId)).n}</b> ({trackerCampaignId})<br /></span> : <></>}
              {'tsCampaignId' in this.props.incident.objectIDs ? <span>TS Campaign: <b>{(getInfoAbout_TsCampaign(info, tsId, trackerCampaignId, tsCampaignId)).n}</b> ({tsCampaignId})<br /></span> : <></>}
              {fourthId}{fourthId ? <br /> : null}
              <br />
              <u><b>Relevant values:</b></u>
              {Object.keys(this.props.incident.val).map(key =>
                <>
                  <br />
                  <span style={styleKey}>{key}:</span>
                  <span style={styleValue}>{this.props.incident.val[key].toLocaleString('en-US', { maximumFractionDigits: 2 })}</span>
                </>
              )}
              <br />
              <br />
            First occurrence: {readableDate(this.props.incident.createdAt)}<br />
            Last occurrence: {readableDate(this.props.incident.updatedAt)}
              <br />
            </Col>
            <Col style={{ textAlign: 'start' }}>
              <u><b>Actions:</b></u>
              {this.props.incident.actions ? Object.keys(this.props.incident.actions).map((actionId) => <IncidentAction key={actionId} details={this.props.incident.actions[actionId]} />) : null}
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={4} />
            <Col md={4} >
              <RaisedButton
                style={{ cursor: 'pointer', paddingLeft: '3px', paddingRight: '3px' }}
                // style={{cursor:'pointer',color:'blue',textDecoration:'underline'}}
                onClick={() => this.openLinkInNewTab(linkToDashboard)}>See in Dashboard</RaisedButton>
            </Col>
            <Col md={4} >
              <RaisedButton
                style={{ cursor: 'pointer', paddingLeft: '3px', paddingRight: '3px' }}
                onClick={() => this.archiveIncidentList([this.props.incident._id])}>Archive</RaisedButton>
            </Col>
          </Row>
        </Container>
        <br />
      </div>
    );
  }
}

// const styleRefresh = {
//   marginTop: 25,
//   marginRight: 0,
//   cursor: 'pointer',
//   zIndex: "999"
// }
const styleKey: CSSProperties = {
  display: 'inline-block',
  minWidth: '120px',
}
const styleValue: CSSProperties = {
  display: 'inline-block',
  minWidth: '80px',
  textAlign: 'end',
}

// const styleToolbar = {
//   marginTop: -30
// }

// const styleTextAreaOutside = {
//   padding:10
// }

// const styleInputCampaignId = {
//   marginRight: 10,
// }

const styleLoaderImg = {
  width: 70,
  height: 70,
  // marginLeft: 550
}
