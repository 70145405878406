
import SIDEBAR from '../Sidebars';
import Toolbar from 'material-ui/Toolbar';
import { FaPowerOff } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import React from 'react';
import Firebase from '../Firebase';
// import utils from '../utils';
import '../App.css';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import Logo from '../logo_app.png';
import Loader from '../loaderImg.gif';
import BASE_URL from '../constants';
import IncidentList from '../components/IncidentList';
import { CSSProperties } from 'react';
import { ISAMI_Incident } from 'src/components/Incident';
import { ERROR_INSTANCE, getInfoAboutPerformanceData, IInfoAboutPerformanceData } from 'src/models/infoAboutPerformanceData';



interface ICurrentProps {
  history: string[],
}
interface ICurrentState {
  incidentList: ISAMI_Incident[],
  infoAboutPerformanceData: IInfoAboutPerformanceData,
  isLoading: boolean,
  hasError: boolean,
}

export default class SAMI_Incidents extends React.Component<ICurrentProps, ICurrentState> {

  constructor(props: ICurrentProps) {

    super(props);

    this.state = {
      isLoading: true,
      hasError: false,
      incidentList: [],
      infoAboutPerformanceData: ERROR_INSTANCE,
    };
  }

  handleLogout() {
    Firebase.logout()
      .then(
        () => {
          this.props.history.push("/login");
        }
      )
      .catch(() => {

      });
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
      incidentList: [],
    });
    return Firebase.getUserToken().then(token => {
      return Promise.all([
        this.getIncidents(token),
        this.getInfoAboutPerformanceData(token),
      ]).then((data) => {
        const incidents: ISAMI_Incident[] = data[0];
        const info: IInfoAboutPerformanceData = data[1];
        this.setState({
          isLoading: false,
          hasError: false,
          incidentList: incidents,
          infoAboutPerformanceData: info,
        });
      }).catch(e => {
        console.error(e.stack);
        this.setState({
          isLoading: false,
          hasError: true,
        });
      });
    });
  }

  getIncidents(token: string): Promise<ISAMI_Incident[]> {
    return fetch(BASE_URL + 'sami_incidents/', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      }
    }).then((response) => {
      if(response.status !== 200) {
        throw new Error(`Server responded with error response code (${response.status}/${response.statusText})`);
      }
      return response.json();
    }).then((responseJson) => {
      if (responseJson.error) {
        const errorStr = "An error occurred!\nReason: " + responseJson.error;
        console.error(errorStr);
        throw new Error(errorStr);
      } else {

        if(!responseJson.SAMI_IncidentsList) {
          const errorStr = `responseJson.SAMI_IncidentsList is null (${responseJson.SAMI_IncidentsList})!`;
          console.error(errorStr);
          throw new Error(errorStr);
        } else {
          // utils.debugLog(`${utils.convertToString(responseJson.SAMI_IncidentsList)}`);
          return responseJson.SAMI_IncidentsList || [];
        }
      }
    });
  }

  getInfoAboutPerformanceData(token: string): Promise<IInfoAboutPerformanceData> {
    return getInfoAboutPerformanceData();
  }

  render() {

    const incidentList: ISAMI_Incident[] = this.state.incidentList || [];
    const infoAboutPerformanceData: IInfoAboutPerformanceData = this.state.infoAboutPerformanceData;
    return (
      <div>
        <MuiThemeProvider>
          <>
            <div id="div">
              <SIDEBAR />
              <Toolbar style={styleToolbar}>
                <img src={Logo} style={styleLogoImg} alt="logo" />
                <h3>SAMI Incidents</h3>
                <ReactTooltip />

                <FaPowerOff data-tip="Logout" style={styleRefresh} onClick={(event) => this.handleLogout()} />
              </Toolbar>
            </div>
            <div>
              <div style={{ textAlign: 'center', marginTop: 10 }}>
                {
                  this.state.isLoading ?
                    <img src={Loader} style={styleLoaderImg} alt="loader" /> :
                    <IncidentList
                      incidents={incidentList}
                      infoAboutPerformanceData={infoAboutPerformanceData}
                      hasError={this.state.hasError}
                    />
                }
              </div>
            </div>
          </>
        </MuiThemeProvider>
      </div>
    );
  }
}

const styleLogoImg = {
  width: 160,
  height: '100%'
}

const styleRefresh: CSSProperties = {
  marginTop: 25,
  marginRight: 0,
  cursor: 'pointer',
  zIndex: 999,
}
const styleToolbar = {
  marginTop: -30
}

const styleLoaderImg = {
  width: 70,
  height: 70,
  // marginLeft: 550
}
