import React from 'react';
import Firebase from './Firebase';
import TABLE from './Table';
import Loader from './loaderImg.gif';
import BASE_URL from './constants';
import ServerErrorIcon from './cross.png';


let lastCol = 0;

export default class TsCampaign_Banners extends React.Component {

  constructor() {
    super()
    this.state = {
      datas: [],
      results: [],
      loaderVisibility: true,
      tableVisibility: false,
      volCampaignHeadLink: "",
      volCampaignHeadText: "",
      tsCampaigns: [],
      serverError: false,
      time: Date.now()
    }

  }

  loadTSData(fromDate, toDate, item1, item2, item3) {
    this.setState({
      loaderVisibility: true,
      tableVisibility: false
    });
    if (fromDate === '' || toDate === '') {
      alert("From & To date cannot be empty!");
      return;
    }
    return Firebase.getUserToken().then(token => {
      return fetch(BASE_URL + "dashboard/performance/" + item1 + "/" + item2 + "/" + item3 + "/banners?fromDate=" + fromDate + "&toDate=" + toDate, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
        }
      }).then((response) => response.json())
        .then((responseJson) => {
          if(responseJson.error) throw responseJson.error;
          var i = 0;
          let lastItem = responseJson.breadcrumbs.length - 1;
          this.setState({
            volCampaignHeadText: responseJson.breadcrumbs[lastItem].display,
            volCampaignHeadLink: responseJson.breadcrumbs[lastItem].link
          });
          var k = 0,
            colObj = null,
            resultOutput = [];
          for (i = 0; i < responseJson.table.rows.length; i++) {
            colObj = {};
            resultOutput.push(responseJson.table.rows[i]);
          }

          lastCol = 0;
          for (i = 0; i < 1; i++) {
            colObj = {};
            for (k = 0; k < responseJson.table.totals.length; k++) {
              colObj[k] = responseJson.table.totals[k];
              lastCol = k;
            }
            colObj[lastCol + 1] = "Count Row";
            resultOutput.push(responseJson.table.totals);
          }
          this.setState({
            columns: responseJson.table.columns,
            results: resultOutput,
            data: resultOutput,
            loaderVisibility: false,
            tableVisibility: true,
            serverError: false,
            fromDateResponse: responseJson.fromDate,
            toDateResponse: responseJson.toDate,
          }, function () {
          });
        })
        .catch((error) => {
          console.error(error);
          alert('Error retreiving data from server!');
          this.setState({
            serverError: true,
            loaderVisibility: false,
          }, function () {
          });
        });
    });
  }

  componentDidMount() {
    console.log(this.props);
    var today = new Date();
    var thisMonth = (today.getMonth() + 1);
    thisMonth = (thisMonth < 10) ? '0' + thisMonth : thisMonth;
    var thisDate = today.getDate();
    thisDate = (thisDate < 10) ? '0' + thisDate : thisDate;

    var fromDate = today.getFullYear() + '-' + thisMonth + '-01T00:00';
    var toDate = today.getFullYear() + '-' + thisMonth + '-' + thisDate + 'T23:59';

    this.loadTSData(fromDate, toDate, this.props.tSId, this.props.tsCampId, this.props.id);

  }

  render() {

    return (
      <div>
        {
          this.state.loaderVisibility ?
            <img src={Loader} style={styleLoaderImg} alt="loader" /> : null
        }
        {this.state.tableVisibility ?
          <div>
            Banners of <a href={this.state.volCampaignHeadLink} rel="noopener noreferrer" target="_blank">{this.state.volCampaignHeadText}</a>
            <TABLE columns={this.state.columns} lastCol={lastCol} results={this.state.results} />
          </div>
          : null}
        {
          this.state.serverError ?
            <img src={ServerErrorIcon} style={styleServerErrorImg} alt="ServerError" /> : null
        }

      </div>
    );
  }

}


const styleLoaderImg = {
  width: 70,
  height: 70,
  marginLeft: 550
}

const styleServerErrorImg = {
  width: "50%",
  height: 300,
  marginLeft: 300,
  marginTop: 20
}
