// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from "firebase/app";
import utils from "./utils";

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";

//*

// APIprofit-Dashboard
const firebaseConfig = {
  apiKey: "AIzaSyDzuIb-YX5vIyvDIc5dVkwq0SMc7zBUbis",
  authDomain: "apiprofit-dashboard.firebaseapp.com",
  databaseURL: "https://apiprofit-dashboard.firebaseio.com",
  projectId: "apiprofit-dashboard",
  storageBucket: "apiprofit-dashboard.appspot.com",
  messagingSenderId: "599967986019",
  appId: "1:599967986019:web:bce9863d733df246e86356",
  measurementId: "G-FYETCF8HYG"
};

/*/

// APIprofit-Dashboard-Dev
var firebaseConfig = {
  apiKey: "AIzaSyAnL8SJzDz3B0koxXQmwdrXwaRckG8mZ-A",
  authDomain: "apiprofit-dashboard-dev.firebaseapp.com",
  databaseURL: "https://apiprofit-dashboard-dev.firebaseio.com",
  projectId: "apiprofit-dashboard-dev",
  storageBucket: "apiprofit-dashboard-dev.appspot.com",
  messagingSenderId: "4313818478",
  appId: "1:4313818478:web:406f89d5818e32786c8d7f",
  measurementId: "G-NT4B7RV2WN"
};
//*/

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.auth().useDeviceLanguage();

function logEvent(strEvent) {
  firebase.analytics().logEvent(strEvent);
  // Example: firebase.analytics().logEvent('notification_received');
}

function register(email,password) {
  return firebase.auth().createUserWithEmailAndPassword(email, password).then(registeredUser=>{
    return registeredUser;
  }).catch(function(error) {
    // Handle Errors here.
    console.error('GFB Error = '+JSON.stringify(error,null,2));
    throw error;
    // Error Examples:
    // {"code":"auth/invalid-email","message":"The email address is badly formatted."}
    // {"code":"auth/email-already-in-use","message":"The email address is already in use by another account."}
  });
}

function login(email,password) {
  return firebase.auth().signInWithEmailAndPassword(email, password).then(registeredUser=>{
    return registeredUser;
  }).catch(function(error) {
    // Handle Errors here.
    console.error('GFB Error = '+JSON.stringify(error,null,2));
    throw error;
  });
}

function logout() {
  return firebase.auth().signOut().then(function() {
    // Sign-out successful.
    return true;
  }).catch(function(error) {
    // An error happened.
    console.error('GFB Error = '+JSON.stringify(error,null,2));
    throw error;
  });
}

function getUserToken() {
  return firebase.auth().currentUser.getIdToken(true).then((idToken)=> {
    return idToken;
  }).catch((error) => {
    // Handle Errors here.
    console.error('GFB Error = '+JSON.stringify(error,null,2));
    throw error;
  });
}

function getUserRoles() {
  let currentLoggedUser=getCurrentUser();
  if(currentLoggedUser) {

    return currentLoggedUser.getIdTokenResult().then((idTokenResult) => {
      let roles=[];
      utils.debugLog('idTokenResult='+JSON.stringify(idTokenResult,null,2));

      if(idTokenResult && idTokenResult.claims) {
        if(idTokenResult.claims.admin) {
          roles.push('admin');
        }
        if(idTokenResult.claims.developer) {
          roles.push('developer');
        }
        // roles=Object.keys(idTokenResult.claims).filter(role=>!!idTokenResult.claims[role]);
      }
      return roles;
    }).catch((error) => {
      // Handle Errors here.
      console.error('GFB Error = '+JSON.stringify(error,null,2));
      throw error;
    });
  } else {
    console.error('FB - No user is logged in!')
    return Promise.resolve([]);
  }
}

function isUserAdmin() {
  return getUserRoles().then((roles)=>roles.includes('admin'));
}

function isLoggedIn() {
  return !!firebase.auth().currentUser;
}

function getCurrentUser() {
  return firebase.auth().currentUser;
}

function updateDisplayName(displayName) {
  const user = firebase.auth().currentUser;

  user.updateProfile({
    displayName: displayName,
  }).then(function() {
    // Update successful.
    return true;
  }).catch(function(error) {
    // An error happened.
    console.error('GFB Error = '+JSON.stringify(error,null,2));
    throw error;
  });
}

function updateUserPhoto(photoURL) {
  const user = firebase.auth().currentUser;

  user.updateProfile({
    photoURL: photoURL, // Example: "https://example.com/jane-q-user/profile.jpg"
  }).then(function() {
    // Update successful.
    return true;
  }).catch(function(error) {
    // An error happened.
    console.error('GFB Error = '+JSON.stringify(error,null,2));
    throw error;
  });
}

function onAuthStateChanged(callback) {
  return firebase.auth().onAuthStateChanged(callback);
}

function changePassword(newPassword) {
  // Important: To set a user's password, the user must have signed in recently. See Re-authenticate a user.
  const user = firebase.auth().currentUser;

  return user.updatePassword(newPassword).then(function() {
    // Update successful.
    return true;
  }).catch((error) => {
    // Handle Errors here.
    console.error('GFB Error = '+JSON.stringify(error,null,2));
    throw error;
  });
}

function sendResetPasswordEmail(email) {
  return firebase.auth().sendPasswordResetEmail(email).then(function() {
    // Email sent.
    return true;
  }).catch(function(error) {
    // An error happened.
    console.error('GFB Error = '+JSON.stringify(error,null,2));
    throw error;
  });
}

function resendEmailVerification() {
  return firebase.auth().currentUser.sendEmailVerification().then(function() {
    // Email sent.
    return true;
  }).catch(function(error) {
    // An error happened.
    console.error('GFB Error = '+JSON.stringify(error,null,2));
    throw error;
  });
}

function screenState() {
  const DEFAULT_STATE={
    debounceCredit: false,
    sidebar: {
      performanceDashboard: true,
      urlBuilder: false,
      websiteDuplicator: false,
      dbContent: false,
      dailyReport: false,
      binomConverter: false,
      SAMI_Incidents: false,
    },
  };

  return getUserRoles().then(roles=>{
    let result=DEFAULT_STATE;
    roles.forEach(role=>{
      switch(role) {
        case 'admin':
        case 'developer':
          result.debounceCredit=true;
          result.sidebar.urlBuilder=true;
          result.sidebar.websiteDuplicator=true;
          result.sidebar.dbContent=true;
          result.sidebar.dailyReport=true;
          result.sidebar.binomConverter=true;
          result.sidebar.SAMI_Incidents=true;
          break;

        case 'partner':
          result.sidebar.urlBuilder=true;
          break;

        default:
          // Leave default
      }
    });
    utils.debugLog(`FB-screenState-getUserRoles - result=${utils.convertToString(result)}`);
    return result;
  });
}

export default {
  logEvent,
  register,
  login,
  logout,
  getUserToken,
  getUserRoles,
  isUserAdmin,
  isLoggedIn,
  getCurrentUser,
  updateDisplayName,
  updateUserPhoto,
  onAuthStateChanged,
  changePassword,
  sendResetPasswordEmail,
  resendEmailVerification,
  screenState,
}