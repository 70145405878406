import BASE_URL from "src/constants";
import Firebase from "src/Firebase";
import utils from "src/utils";

enum FIELD_STATE {
  UNKNOWN = "Unknown",
  ERR = "Error!",
  NOT_SET = "NOT_SET",
}

export enum BANNER_TYPE {
  IMAGE = "IMAGE",
  PREROLL = "PREROLL",
  DIRECT_LINK = "DIRECT_LINK",
  ERR = "Error!",
  NON_EXISTING = "NON_EXISTING",
}

interface IGeneralInfoAboutZones {
  [id: string]: ITsZone_Info_perTracker_Serialized,
}
interface ITracker_Info_Serialized {
  c_: {
    [id: string]: ITrafficSource_Info_Serialized,
  },
  zones: IGeneralInfoAboutZones,
}
interface ITrafficSource_Info_Serialized {
  n: string, // name
  c_: { // childrenTrackerCampaigns
    [id: string]: ITrackerCampaign_Info_Serialized
  },
};
interface ITrackerCampaign_Info_Serialized {
  n: string, // name

  c_: { // childrenTsCampaigns
    [id: string]: ITsCampaign_Info_Serialized,
  },
};
interface ITsCampaign_Info_Serialized {
  n: string, // name
  iA: FIELD_STATE | boolean, // isActive

  cb: { // childrenTsBanners
    [id: string]: ITsBanner_Info_Serialized,
  },
  ct: { // childrenTsTargets
    [id: string]: ITsTarget_Info_Serialized,
  },
  cz: { // childrenTsZones
    [id: string]: ITsZone_Info_perTsCampaign_Serialized,
  },
}
interface ITsBanner_Info_Serialized {
  n: string, // name
  m: FIELD_STATE | {
    url: string,
    type: BANNER_TYPE,
  },  // bannerMedia
  iA: FIELD_STATE | boolean, // isActive
}
interface ITsTarget_Info_Serialized {
  n: string, // name
}
interface ITsZone_Info_perTsCampaign_Serialized {
  iA: FIELD_STATE | boolean, // isActive
}
interface ITsZone_Info_perTracker_Serialized {
  n: string, // name
}
type ITsZone_Info_Serialized = ITsZone_Info_perTracker_Serialized & ITsZone_Info_perTsCampaign_Serialized;

export interface IInfoAboutPerformanceData extends ITracker_Info_Serialized {
  createdAt: Date,
  updatedAt: Date,
}

export const ERROR_INSTANCE: IInfoAboutPerformanceData = {
  c_: {},
  zones: {},
  createdAt: new Date('1970'),
  updatedAt: new Date('1970'),
};

const CACHE_KEY = "InfoAboutPerformanceData";
const ONE_MINUTE = 60 * 1000;
let _infoAboutPerformanceData: IInfoAboutPerformanceData | null = null;

export function getInfoAboutPerformanceData(): Promise<IInfoAboutPerformanceData> {

  if (_infoAboutPerformanceData) {
    return Promise.resolve(_infoAboutPerformanceData);
  } else {
    const fromCache: {
      value: string,
      isExpired: boolean,
      timestamp: Date,
    } | null = utils.getCache(CACHE_KEY, 15 * ONE_MINUTE);
    if (fromCache && (!fromCache.isExpired)) {
      return Promise.resolve(JSON.parse(fromCache.value));
    } else {
      return getInfoAboutPerformanceData_fromServer().catch(e => {
        return ERROR_INSTANCE;
      });
    }
  }
}

function getInfoAboutPerformanceData_fromServer(): Promise<IInfoAboutPerformanceData> {
  return Firebase.getUserToken().then(token => {
    console.log('BASE_URL='+BASE_URL);
    return fetch(BASE_URL + 'performance/info', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token,
      }
    }).then((response) => {
      if(response.status !== 200) {
        throw new Error(`Server responded with error response code (${response.status}/${response.statusText})`);
      }
      return response.json();
    }).then((responseJson: IInfoAboutPerformanceData) => {
      if (!responseJson) {
        const errorStr = `An error occurred!\nReason: responseJson is false (${responseJson})`;
        console.error(errorStr);
        throw new Error(errorStr);
      // } else if (responseJson.error) {
      //   const errorStr = "An error occurred!\nReason: " + responseJson.error;
      //   console.error(errorStr);
      //   throw new Error(errorStr);
      } else {
        _infoAboutPerformanceData = responseJson;
        utils.setCache(CACHE_KEY, JSON.stringify(responseJson), false);
        return responseJson;
      }
    }).catch((error) => {
      const errorStr = `An error occurred!\nReason: ${error.stack}`;
      console.error(errorStr);
      throw new Error(errorStr);
    });
  });
}

export function getInfoAbout_TS(info: IInfoAboutPerformanceData, tsId: string): ITrafficSource_Info_Serialized {
  const errResponse = {
    n: "<Loading...>",
    c_: {},
  };

  try {
    return info.c_[tsId] || errResponse;
  } catch(e) {
    console.error(`Error: Couldn't get the info for tsId=${tsId}, details: ${e.stack}`);
    return errResponse;
  }
}

export function getInfoAbout_TrackerCampaign(info: IInfoAboutPerformanceData, tsId: string, trackerCampaignId: string): ITrackerCampaign_Info_Serialized {
  const errResponse = {
    n: "<Loading...>",
    c_: {},
  };

  try {
    return info.c_[tsId].c_[trackerCampaignId] || errResponse;
  } catch(e) {
    console.error(`Error: Couldn't get the info for tsId=${tsId}, trackerCampaignId=${trackerCampaignId}, details: ${e.stack}`);
    return errResponse;
  }
}


export function getInfoAbout_TsCampaign(info: IInfoAboutPerformanceData, tsId: string, trackerCampaignId: string, tsCampignId: string): ITsCampaign_Info_Serialized {
  const errResponse = {
    n: "<Loading...>",
    iA: FIELD_STATE.ERR,
    cb: {},
    ct: {},
    cz: {},
  };

  try {
    return info.c_[tsId].c_[trackerCampaignId].c_[tsCampignId] || errResponse;
  } catch(e) {
    console.error(`Error: Couldn't get the info for tsId=${tsId}, trackerCampaignId=${trackerCampaignId}, tsCampignId=${tsCampignId}, details: ${e.stack}`);
    return errResponse;
  }
}

export function getInfoAbout_TsBanner(info: IInfoAboutPerformanceData, tsId: string, trackerCampaignId: string, tsCampignId: string, tsBannerId: string): ITsBanner_Info_Serialized {
  const errResponse = {
    n: "<Loading...>",
    iA: FIELD_STATE.ERR,
    m: FIELD_STATE.ERR,
  };

  try {
    return info.c_[tsId].c_[trackerCampaignId].c_[tsCampignId].cb[tsBannerId] || errResponse;
  } catch(e) {
    console.error(`Error: Couldn't get the info for tsId=${tsId}, trackerCampaignId=${trackerCampaignId}, tsCampignId=${tsCampignId}, tsBannerId=${tsBannerId}, details: ${e.stack}`);
    return errResponse;
  }
}

export function getInfoAbout_TsTarget(info: IInfoAboutPerformanceData, tsId: string, trackerCampaignId: string, tsCampignId: string, tsTargetId: string): ITsTarget_Info_Serialized {
  const errResponse = {
    n: "<Loading...>",
  };

  try {
    return info.c_[tsId].c_[trackerCampaignId].c_[tsCampignId].ct[tsTargetId] || errResponse;
  } catch(e) {
    console.error(`Error: Couldn't get the info for tsId=${tsId}, trackerCampaignId=${trackerCampaignId}, tsCampignId=${tsCampignId}, tsTargetId=${tsTargetId}, details: ${e.stack}`);
    return errResponse;
  }
}

export function getInfoAbout_TsZone(info: IInfoAboutPerformanceData, tsId: string, trackerCampaignId: string, tsCampignId: string, tsZoneId: string): ITsZone_Info_Serialized {
  const errResponse = {
    n: "<Loading...>",
    iA: FIELD_STATE.ERR,
  };

  try {
    return {
      n: info.zones[tsZoneId].n || "<Loading...>",
      iA: ('iA' in info.c_[tsId].c_[trackerCampaignId].c_[tsCampignId].cz[tsZoneId]) ? info.c_[tsId].c_[trackerCampaignId].c_[tsCampignId].cz[tsZoneId].iA : FIELD_STATE.ERR,
    };
  } catch (e) {
    console.error(`Error: Couldn't get the info for tsId=${tsId}, trackerCampaignId=${trackerCampaignId}, tsCampignId=${tsCampignId}, tsZoneId=${tsZoneId}, details: ${e.stack}`);
    return errResponse;
  }
}
