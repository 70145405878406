import React from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaCaretUp, FaCaretDown } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';


export default class Table extends React.Component {

  constructor() {

    super();
    this.state = {
      isTsView: false,
      isTsData: false,
    }

  }

  componentDidMount() {
    this.setState({

    }, function () {

    });
  }

  CellFormatter(cell) {
    if (typeof cell === "object") {
      if (cell) {
        return (<div data-tip={cell.display}><a href={cell.link}>{cell.display}</a></div>);
      } else {
        console.error('cell=null');
        return 'ERR';
      }
    } else {
      return (<span data-tip={cell}>{cell}</span>);
    }
  }

  getCaret(direction) {
    if (direction === 'asc') {
      return (
        <FaCaretUp />
      );
    }
    if (direction === 'desc') {
      return (
        <FaCaretDown />
      );
    }
  }


  revertSortFunc(a, b, order, rowIdx) {
    const result = Object.values(a);
    if (typeof a[rowIdx] !== "string" && typeof result[0] === "object") {
      if (a[rowIdx].display) {
        if (order === 'desc') {
          return (a[rowIdx].display).localeCompare(b[rowIdx].display);
        } else {
          return (b[rowIdx].display).localeCompare(a[rowIdx].display);
        }
      } else {
        if (order === 'desc') {
          return (a[rowIdx]) - (b[rowIdx]);
        } else {
          return (b[rowIdx]) - (a[rowIdx]);
        }
      }
    }
    if (typeof a[rowIdx] === "string" && typeof result[0] === "object") {
      if (order === 'desc') {
        return (a[rowIdx]).localeCompare(b[rowIdx]);
      } else {
        return (b[rowIdx]).localeCompare(a[rowIdx]);
      }
    }
  }

  imageFormatter(cell) {
    if (cell && cell.type && cell.type === "IMAGE") {
      return <img style={{ width: '70%', height: '20%' }} alt="data" src={cell.url} />
    }
  }



  render() {
    const rowStyleFormat = (i, rowIdx) => {
      if (this.props.results.length > 1 && rowIdx === this.props.results.length - 1) {
        return {
          backgroundColor: '#4A526A',
          color: 'white',
          title: "kkk"
        }
      }
    };

    return (
      <div style={styleT}>

        <BootstrapTable
          data={this.props.results} striped hover condensed
          headerStyle={{ backgroundColor: '#66708B', fontSize: 13 }}
          trStyle={rowStyleFormat}
          wrapperClasses="table-responsive"
        >
          {this.props.columns.map((value) => {
            if (value['type'] === "string" && value['filterable'] === true && value['sortable'] === true) {
              return <TableHeaderColumn isKey={value['isTableKey'] || false} width={value['width'] || '200'} dataFormat={this.CellFormatter} caretRender={this.getCaret} dataSort={true} sortFunc={this.revertSortFunc} key={value['key']} dataField={value['key']}>{value['label']}</TableHeaderColumn>
            }
            if (value['type'] === "media" && value['filterable'] === false && value['sortable'] === false) {
              return <TableHeaderColumn isKey={value['isTableKey'] || false} width={value['width'] || '200'} dataFormat={this.imageFormatter} dataField={value['key']} key={value['key']}>{value['label']}</TableHeaderColumn>
            }
            if (value['filterable'] === true && value['sortable'] === true) {
              return <TableHeaderColumn isKey={value['isTableKey'] || false} width={value['width'] || '200'} dataFormat={this.CellFormatter} filter={{ type: 'TextFilter', placeholder: value['filterPlaceholder'], style: { fontSize: 13 } }} caretRender={this.getCaret} dataSort={true} sortFunc={this.revertSortFunc} key={value['key']} dataField={value['key']}>{value['label']}</TableHeaderColumn>
            }
            if (value['filterable'] === true && value['sortable'] !== true) {
              return <TableHeaderColumn isKey={value['isTableKey'] || false} width={value['width'] || '200'} dataFormat={this.CellFormatter} filter={{ type: 'TextFilter', placeholder: value['filterPlaceholder'], style: { fontSize: 13 } }} key={value['key']} dataField={value['key']}>{value['label']}</TableHeaderColumn>
            }
            if (value['sortable'] === true && value['filterable'] !== true) {
              return <TableHeaderColumn isKey={value['isTableKey'] || false} width={value['width'] || '200'} dataFormat={this.CellFormatter} caretRender={this.getCaret} dataSort={true} sortFunc={this.revertSortFunc} key={value['key']} dataField={value['key']}>{value['label']}</TableHeaderColumn>
            }
            return <TableHeaderColumn isKey={value['isTableKey'] || false} width={value['width'] || '200'} dataFormat={this.CellFormatter} key={value['key']} dataField={value['key']}>{value['label']}</TableHeaderColumn>
          })}
        </BootstrapTable>
        <ReactTooltip />
      </div>
    );

  }
}

const styleT = {
  backgroundColor: "white",
}