function isDebug() {

  return ((typeof Storage !== "undefined") && localStorage.getItem('dd'));
}

function debugLog(str) {
  if(isDebug()) {
    console.log(str);
  }
}

function setCache(key,value,forSessionStorage) {
  if(typeof Storage !== "undefined") {
    let item=JSON.stringify({
      value: value,
      timestamp: new Date().getTime(),
      type: typeof(value),
    });
    if(forSessionStorage) {
      sessionStorage.setItem(key,item);
    } else {
      localStorage.setItem(key,item);
    }
  }
}

function getCache(key,deltaInMilliseconds) {
  if(typeof Storage !== "undefined") {
    let data=sessionStorage.getItem(key);
    let isTakenFromSession=true;

    if(!data) {
      data=localStorage.getItem(key);
      isTakenFromSession=false;
    }

    if(data) {
      data=JSON.parse(data);
      let isExpired=deltaInMilliseconds < ((new Date().getTime())-data.timestamp);

      debugLog(key+' - found'+(isTakenFromSession?' in session':'')+'!'+(isExpired?' But expired '+(((new Date().getTime())-data.timestamp)/1000)+ 'seconds ago, and will be deleted...':''));

      if(isExpired) {
        if(isTakenFromSession) {
          sessionStorage.removeItem(key);
        } else {
          localStorage.removeItem(key);
        }
      }
      return {
        value: data.value,
        isExpired: isExpired,
        timestamp: new Date(data.timestamp),
      }
    } else {
      debugLog(key+' - not found');
      return null;
    }
  } else {
    debugLog(key+' - Storage not supported');
    return null;
  }
}


function convertToString(value) {

  const isError = (obj)=>{
      return Object.prototype.toString.call(obj) === "[object Error]";
    // return obj && obj.stack && obj.message && typeof obj.stack === 'string'
    //        && typeof obj.message === 'string';
  }

  try {
    switch(typeof(value)) {
      case 'string':
      case 'boolean':
      case 'number':
      case 'undefined':
      default:
        return ""+value;
      case 'object':
        if(isError(value)) {
          return value.stack;
        } else {
          return JSON.stringify(value, null, 2);
        }
    }
  } catch (e) {
    return ""+value;
  }
}

export default {
  isDebug,
  debugLog,
  setCache,
  getCache,
  convertToString,
};