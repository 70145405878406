import React from "react";
import Sidebar from "react-sidebar";
import { MdClose } from "react-icons/md";
import { GoThreeBars } from "react-icons/go";
import Firebase from './Firebase';
import CompanyLogo from './company-logo.png';
import ItemImg from './item-img.png';
import { style } from 'glamor';

export default class Sidebars extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: false
    };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  componentDidMount() {
    Firebase.screenState().then((data)=>{
      this.setState({
        performanceLink: data.sidebar.performanceDashboard,
        dailyReportLink: data.sidebar.dailyReport,
        dbContentLink: data.sidebar.dbContent,
        urlBuilderLink: data.sidebar.urlBuilder,
        websiteDuplicatorLink: data.sidebar.websiteDuplicator,
        binomConverterLink: data.sidebar.binomConverter,
        SAMI_IncidentsLink: data.sidebar.SAMI_Incidents,
      });
    });
  }

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }

  handleLogout() {
    Firebase.logout()
      .then(
        () => {
          this.props.history.push("/login");
        }
      )
      .catch(() => {

      });
  }

  handleDailyReport() {
    window.open("https://docs.google.com/spreadsheets/d/1cCksQ7XXJcuAWbFzVLqPXcmoM_4dGEsEJ69jHohVBDw/edit", "_blank");
  }

  render() {
    return (
      <Sidebar

        sidebar={<div><MdClose onClick={() => this.onSetSidebarOpen(false)} style={styleMdClose} />
          <img src={CompanyLogo} alt="logo" />
          <div style={styleItemsDiv}>
            {this.state.performanceLink ?
            <a href="/" className={styles.hoverButton}>
              <div style={styleItemDivSeparator} className={styles.redHoverbutton}>
                <img src={ItemImg} style={styleItemImg} alt="ItemImg" /><div style={styleItemDiv}><b>Performance</b></div>
              </div>
            </a>
            : null }
            {this.state.SAMI_IncidentsLink ?
            <a href="/#/sami_incidents" className={styles.hoverButton}>
              <div style={styleItemDivSeparator} className={styles.redHoverbutton}>
                <img src={ItemImg} style={styleItemImg} alt="ItemImg" /><div style={styleItemDiv}><b>SAMI Incidents</b></div>
              </div>
            </a>
            : null }
            {this.state.binomConverterLink ?
            <a href="/#/binom-campaign-converter" className={styles.hoverButton}>
              <div style={styleItemDivSeparator} className={styles.redHoverbutton}>
                <img src={ItemImg} style={styleItemImg} alt="ItemImg" /><div style={styleItemDiv}><b>Binom Converter</b></div>
              </div>
            </a>
            : null }
            {this.state.urlBuilderLink ?
            <a href="/#/urlbuilder" className={styles.hoverButton}>
              <div style={styleItemDivSeparator} className={styles.redHoverbutton}>
                <img src={ItemImg} style={styleItemImg} alt="ItemImg" /><div style={styleItemDiv}><b>URL Builder</b>
                </div>
              </div>
            </a>
            : null }
            {this.state.websiteDuplicatorLink ?
            <a href="/#/siteduplicator" className={styles.hoverButton}>
              <div style={styleItemDivSeparator} className={styles.redHoverbutton}>
                <img src={ItemImg} style={styleItemImg} alt="ItemImg" /><div style={styleItemDiv}><b>Site Duplicator</b></div>
              </div>
            </a>
            : null }
            {this.state.dbContentLink ?
            <a href="/#/dbcontent" className={styles.hoverButton}>
              <div style={styleItemDivSeparator} className={styles.redHoverbutton}>
                <img src={ItemImg} style={styleItemImg} alt="ItemImg" /><div style={styleItemDiv}><b>DB Content</b></div>
              </div>
            </a>
            : null }
            {this.state.dailyReportLink ?
            <a href="https://docs.google.com/spreadsheets/d/1cCksQ7XXJcuAWbFzVLqPXcmoM_4dGEsEJ69jHohVBDw/edit" rel="noopener noreferrer" target="_blank" className={styles.hoverButton}>
              <div style={styleItemDivSeparator} className={styles.redHoverbutton}>
                <img src={ItemImg} style={styleItemImg} alt="ItemImg" /><div style={styleItemDiv}><b>Daily Report</b></div>
              </div>
            </a>
            : null }
            <a href="/" onClick={() => this.handleLogout()} className={styles.hoverButton}>
              <div style={styleItemDivSeparator} className={styles.redHoverbutton}>
                <img src={ItemImg} style={styleItemImg} alt="ItemImg" /><div style={styleItemDiv}><b>Logout</b></div>
              </div>
            </a>
          </div>
        </div>}
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        styles={
          {
            root: {
              position: undefined,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: "hidden"
            },
            sidebar: {
              zIndex: 2,
              position: "absolute",
              top: 0,
              bottom: 0,
              transition: "transform .3s ease-out",
              WebkitTransition: "-webkit-transform .3s ease-out",
              willChange: "transform",
              overflowY: "auto",
              background: "#8992a9", width: "250px"
            },
            content: {
              position: undefined,
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflowY: "auto",
              WebkitOverflowScrolling: "touch",
              transition: "left .3s ease-out, right .3s ease-out"
            },
            overlay: {
              zIndex: 1,
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              opacity: 0,
              visibility: "hidden",
              transition: "opacity .3s ease-out, visibility .3s ease-out",
              backgroundColor: "rgba(0,0,0,.3)"
            },
            dragHandle: {
              zIndex: 1,
              position: "fixed",
              top: 0,
              bottom: 0
            }
          }
        }

      >

        <GoThreeBars onClick={() => this.onSetSidebarOpen(true)} style={styleGo} />
      </Sidebar>
    );
  }
}

const styleMdClose = {
  width: '10%',
  height: '10%',
  cursor: 'pointer',
  color: 'white'
}

const styleGo = {
  width: 25,
  height: 25,
  cursor: 'pointer',
}

const styleItemsDiv = {
  cursor: 'pointer',
}

const styleItemDiv = {
  marginBottom: 20,
  marginLeft: 60,
}

const styleItemDivSeparator = {
  borderBottom: '1px solid #9ca4b8',
  color: 'black',
}

const styleItemImg = {
  width: 20,
  height: 14,
  marginBottom: -44,
  marginLeft: 30,
}

const styles = {
  redHoverbutton: style({

  }),
  hoverButton: style({

    ":hover": {
      textDecorationColor: 'black',
    }
  })
};