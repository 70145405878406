
// import SIDEBAR from '../Sidebars';
// import Toolbar from 'material-ui/Toolbar';
// import { FaPowerOff } from 'react-icons/fa';
// import ReactTooltip from 'react-tooltip';
import React from 'react';
// import Firebase from '../Firebase';
// import utils from '../utils';
// import '../App.css';
// import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
// import AppBar from 'material-ui/AppBar';
// import TextField from 'material-ui/TextField';
// import Logo from '../logo_app.png';
// import RaisedButton from 'material-ui/RaisedButton';
// import {Container, Row, Col} from 'react-bootstrap';
// import Loader from '../loaderImg.gif';
// import BASE_URL, {CLASS_TYPE} from '../constants';

export interface IIncidentAction {
  result: {
    hasError: boolean,
    timestamp: Date,
    message: string,
  },
  type: actionType,
}

enum actionType {
  STOP_TS_ZONE = "STOP_TS_ZONE",
  OTHER = "???"
}

interface ICurrentState {

}

interface ICurrentProps {
  details: IIncidentAction,
}

function readableDate(date: Date) {
  return new Date(date).toISOString().replace("T"," ").substr(0,19);
}

export default class IncidentAction extends React.Component<ICurrentProps, ICurrentState> {

  actionThatWasPerformed() {

    return <div>
      Timestamp: {readableDate(this.props.details.result.timestamp)}<br/>
      {this.props.details.result.message ? `Output: ${this.props.details.result.message}` : null}
    </div>
  }

  render() {
    let styles;
    let status;
    if(this.props.details.result) {
      if(this.props.details.result.hasError) {
        styles=ActionListItem_failure;
        status = 'Failed!';
      } else {
        styles=ActionListItem_success;
        status = 'Success!';
      }
    } else {
      styles=ActionListItem_pending;
      status = 'Pending...';
    }
    return <div style={styles}>
      <div style={{textAlign: 'center'}}><b><u>{this.props.details.type === `STOP_TS_ZONE` ? 'Stop TS Zone' : this.props.details.type}</u></b></div>
      Status: {status}
      {this.props.details.result?this.actionThatWasPerformed():null}
    </div>
  }
}

const ActionListItem = {
  border: '1px black solid',
  padding: '5px',
}
const ActionListItem_success = {
  ...ActionListItem,
  background: 'lightgreen',
}
const ActionListItem_failure = {
  ...ActionListItem,
  background: 'palevioletred',
}
const ActionListItem_pending = {
  ...ActionListItem,
  background: 'lightgray',
}

// const styleRefresh = {
//   marginTop: 25,
//   marginRight: 0,
//   cursor: 'pointer',
//   zIndex: "999"
// }
// const styleTextArea = {
//   width: '100%',
//   height: '100%',
//   border: '0px solid #ccc',
//   paddingLeft:10,
//   paddingBottom:10
// }

// const styleToolbar = {
//   marginTop: -30
// }

// const styleTextAreaOutside = {
//   padding:10
// }

// const styleInputCampaignId = {
//   marginRight: 10,
// }

// const styleLoaderImg = {
//   width: 70,
//   height: 70,
//   // marginLeft: 550
// }
