import React from 'react';
import Firebase from './Firebase';
import './App.css';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import TextField from 'material-ui/TextField';
import Toolbar from 'material-ui/Toolbar';
import Logo from './logo_app.png';
import Loader from './loaderImg.gif';
import { FaUndo } from 'react-icons/fa';
import { FaPowerOff } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import TABLE from './Table';
import BASE_URL from './constants';
import SIDEBAR from './Sidebars';
import DebounceCredit from './debounceCredit';
import Breadcrumb from './breadcrumb';

let lastCol = 0;

export default class Dashboard extends React.Component {

  constructor() {
    super()
    this.state = {
      datas: [],
      results: [],
      loaderVisibility: true,
      tableVisibility: false,
      time: Date.now()
    }

  }

  handleLogout() {
    Firebase.logout()
      .then(
        () => {
          this.props.history.push("/login");
        }
      )
      .catch(() => {

      });
  }

  loadTSData(fromDate, toDate) {
    this.setState({
      loaderVisibility: true,
      tableVisibility: false
    });
    if (fromDate === '' || toDate === '') {
      alert("From & To date cannot be empty!");
      return;
    }
    return Firebase.getUserToken().then(token => {

      return fetch(BASE_URL + 'dashboard/performance?fromDate=' + fromDate + '&toDate=' + toDate, {
        method: 'GET',
        headers: {
          Authorization: 'Bearer ' + token,
        }
      }).then((response) => response.json())
        .then((responseJson) => {
          if(responseJson.error) throw responseJson.error;
          var i = 0, k = 0,
            colObj = null,
            resultOutput = [];
          var breadcrumbOutput = [];
          for (let x = 0; x < responseJson.breadcrumbs.length; x++) {
            breadcrumbOutput.push(responseJson.breadcrumbs[x]);
          }
          for (i = 0; i < responseJson.table.rows.length; i++) {
            colObj = {};
            resultOutput.push(responseJson.table.rows[i]);
          }

          lastCol = 0;
          for (i = 0; i < 1; i++) {
            colObj = {};
            for (k = 0; k < responseJson.table.totals.length; k++) {
              colObj[k] = responseJson.table.totals[k];
              lastCol = k;
            }
            colObj[lastCol + 1] = "Count Row";
            resultOutput.push(responseJson.table.totals);
          }
          this.setState({
            columns: responseJson.table.columns,
            results: resultOutput,
            data: resultOutput,
            loaderVisibility: false,
            tableVisibility: true,
            fromDateResponse: responseJson.fromDate,
            toDateResponse: responseJson.toDate,
            breadcrumbs: breadcrumbOutput
          }, function () {
          });
        })
        .catch((error) => {
          console.error(error);
          alert('Error retreiving data from server!');
        });
    });
  }

  handleRefresh() {
    var fromDate = document.getElementById("datetime-local-from").value;
    var toDate = document.getElementById("datetime-local-to").value;
    this.loadTSData(fromDate, toDate);
    this.setState({
      time: Date.now()
    });
  }

  componentDidMount() {
    // console.log(this.props);
    var today = new Date();
    var thisMonth = (today.getMonth() + 1);
    thisMonth = (thisMonth < 10) ? '0' + thisMonth : thisMonth;
    var thisDate = today.getDate();
    thisDate = (thisDate < 10) ? '0' + thisDate : thisDate;

    var fromDate = today.getFullYear() + '-' + thisMonth + '-01T00:00';
    var toDate = today.getFullYear() + '-' + thisMonth + '-' + thisDate + 'T23:59';

    document.getElementById("datetime-local-from").value = fromDate;
    document.getElementById("datetime-local-to").value = toDate;
    this.loadTSData(fromDate, toDate);
  }

  CellFormatter(cell, row) {
    if (typeof row != "object") {
      return (<div><a href={"/#2/" + row}>{cell}</a></div>);
    } else {
      return (<div>{cell}</div>);
    }
  }

  render() {

    return (

      <div>

        <MuiThemeProvider>
          <div id="div">
            <SIDEBAR />
            <Toolbar style={styleToolbar}>
              <img src={Logo} style={styleLogoImg} alt="logo" />
              <h3>Traffic Sources - Overview</h3>
              <FaUndo data-tip="Refresh" style={styleRefresh} onClick={(event) => this.handleRefresh(event)} />
              <ReactTooltip />
              <label style={styleText}>From:</label><TextField style={styleDateText} id="datetime-local-from" label="Next appointment" type="datetime-local" />
              <label style={styleText}>To:</label><TextField style={styleDateText} id="datetime-local-to" label="Next appointment" type="datetime-local" />

              <FaPowerOff data-tip="Logout" style={styleRefresh} onClick={(event) => this.handleLogout(event)} />
            </Toolbar>
            {this.state.tableVisibility ?
              <DebounceCredit time={this.state.time} />
              : null}
          </div>
        </MuiThemeProvider>
        {
          this.state.loaderVisibility ?
            <img src={Loader} style={styleLoaderImg} alt="loader" /> : null
        }
        {this.state.tableVisibility ?
          <div style={tblDiv}>
            <div>
              <Breadcrumb breadcrumbs={this.state.breadcrumbs} fromDateResponse={this.state.fromDateResponse} toDateResponse={this.state.toDateResponse} />
            </div>
            <TABLE columns={this.state.columns} lastCol={lastCol} results={this.state.results} />
          </div>
          : null}

      </div>
    );
  }
}

const styleLogoImg = {
  width: 160,
  height: '100%'
}

const styleRefresh = {
  marginTop: 25,
  marginRight: 0,
  cursor: 'pointer',
  zIndex: "999"
}

const styleDateText = {
  marginTop: 5,
}

const styleText = {
  marginTop: 20,
}

const styleLoaderImg = {
  width: 70,
  height: 70,
  marginLeft: 550
}

const tblDiv = {
  width: '95%',
  marginTop: '5%',
  marginLeft: '2.5%',
  zIndex: "-999"
}

const styleToolbar = {
  marginTop: -30
}