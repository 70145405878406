import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import utils from './utils';
import Login from './Login';
import UrlBuilder from './urlBuilder';
import Firebase from './Firebase';
import * as serviceWorker from './serviceWorker';
import { hotjar } from 'react-hotjar';
import {
  HashRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Dashboard from './Dashboard';
import TSVIEW from './tsView';
import VolCampaignNormalPage from './VolCampaignNormalPage';
import VolCampaignSpecialPage from './VolCampaignSpecialPage';
import TsCampaignSpecialPage from './TsCampaignSpecialPage';
import SiteDuplicator from './siteDuplicator';
import DBContent from './dBContent';
import LogRocket from 'logrocket';
import BinomCampaignConverter from './binomCampaignConverter';
import SAMI_Incidents from './pages/SAMI_Incidents';

const _HotJar={
  hjid: `${process.env.HOT_JAR_ID||''}`,
  hjsv: `${process.env.HOT_JAR_SV||''}`,
};
if(_HotJar.hjid && _HotJar.hjsv) {
  utils.debugLog('HotJar='+JSON.stringify(_HotJar,null,2));
  hotjar.initialize(_HotJar.hjid, _HotJar.hjsv);
}
if (process.env.LogRocket) LogRocket.init(process.env.LogRocket);
Firebase.onAuthStateChanged(checkIfLoggedInAndVerified.bind(this));
checkIfLoggedInAndVerified.bind(this);

var urlBuilderLink = false;
var websiteDuplicatorLink = false;
var dbContentLink = false;
var binomConverterLink = false;
var samiIncidentsLink = false;

// var dl;

async function checkIfLoggedInAndVerified() {

  if (Firebase.isLoggedIn()) {
    utils.debugLog('loggedInUser=' + JSON.stringify(Firebase.getCurrentUser(), null, 2));
    if (Firebase.getCurrentUser().emailVerified) {
      if (process.env.LogRocket) LogRocket.identify(Firebase.getCurrentUser().email, {
        email: Firebase.getCurrentUser().email,
        roles: Firebase.getUserRoles()
      });

      await Firebase.screenState().then((data) => {
        urlBuilderLink = data.sidebar.urlBuilder;
        websiteDuplicatorLink = data.sidebar.websiteDuplicator;
        dbContentLink = data.sidebar.dbContent;
        binomConverterLink = data.sidebar.binomConverter;
        samiIncidentsLink = data.sidebar.SAMI_Incidents;
      })

      //dl = await dbContentLink;

      // console.log(dbContentLink);
      ReactDOM.render(
        <HashRouter>
          <App />
        </HashRouter>,
        document.getElementById('root')
      )
    } else {
      ReactDOM.render(<Login isLoggedInButNotVerified={true} />, document.getElementById('root'));
    }
  } else {
    ReactDOM.render(
      <HashRouter>
        <App />
      </HashRouter>,
      document.getElementById('root')
    )
  }
}

function App() {
  return (
    <main>
      <Switch>
        <Route exact path="/login" component={Login} />
        <UrlBuilderRoute exact path="/urlbuilder" component={UrlBuilder} />
        <PrivateRoute exact path="/" component={Dashboard} />
        <PrivateRoute exact path="/2/:tSId" component={TSVIEW} />
        <PrivateRoute exact path="/3/:tSId/:id" component={VolCampaignNormalPage} />
        <PrivateRoute exact path="/3_special/:tSId/:id" component={VolCampaignSpecialPage} />
        <PrivateRoute exact path="/4_special2/:tSId/:tsCampId/:id" component={TsCampaignSpecialPage} />
        <BinomConverterRoute exact path="/binom-campaign-converter" component={BinomCampaignConverter} />
        <SamiIncidentsRoute exact path="/sami_incidents" component={SAMI_Incidents} />
        <SiteDuplicatorRoute exact path="/siteduplicator" component={SiteDuplicator} />
        <DBContentRoute exact path="/dbcontent" component={DBContent} />
        <NotFoundRoute path='*' exact={true} component={Dashboard} />
      </Switch>
    </main>
  )

}

function PrivateRoute({ component: Component, ...rest }) {
  return <Route {...rest} render={(props) => (
    Firebase.isLoggedIn() && Firebase.getCurrentUser().emailVerified === true
      ? <Component {...props} />
      : <Redirect to={{
        pathname: '/login',
        state: { from: props.location }
      }} />
  )} />
}

function NotFoundRoute({ component: Component, ...rest }) {
  return <Route {...rest} render={() => (
    Firebase.isLoggedIn() && Firebase.getCurrentUser().emailVerified === true
      ? <Redirect from='*' to='/' />
      : Component
  )} />
}

function UrlBuilderRoute({ component: Component, ...rest }) {
  return <Route {...rest} render={(props) => (
    Firebase.isLoggedIn() && Firebase.getCurrentUser().emailVerified === true && urlBuilderLink
      ? <Component {...props} />
      : <Redirect from='*' to='/' />
  )} />
}

function SiteDuplicatorRoute({ component: Component, ...rest }) {
  return <Route {...rest} render={(props) => (
    Firebase.isLoggedIn() && Firebase.getCurrentUser().emailVerified === true && websiteDuplicatorLink
      ? <Component {...props} />
      : <Redirect from='*' to='/' />
  )} />
}

// var dblink;
// async function getfbval() {
//   await Firebase.screenState().then((data) => {
//     dblink = data.sidebar.dbContent
//   });
//   return Promise.resolve(dblink);
// }

function DBContentRoute({ component: Component, ...rest }) {
  // console.log(dbContentLink);
  return <Route {...rest} render={(props) => (
    Firebase.isLoggedIn() && Firebase.getCurrentUser().emailVerified === true && dbContentLink
      ? <Component {...props} />
      : <Redirect from='*' to='/' />
  )} />

}

function BinomConverterRoute({ component: Component, ...rest }) {
  return <Route {...rest} render={(props) => (
    Firebase.isLoggedIn() && Firebase.getCurrentUser().emailVerified === true && binomConverterLink
      ? <Component {...props} />
      : <Redirect from='*' to='/' />
  )} />
}

function SamiIncidentsRoute({ component: Component, ...rest }) {
  return <Route {...rest} render={(props) => (
    Firebase.isLoggedIn() && Firebase.getCurrentUser().emailVerified === true && samiIncidentsLink
      ? <Component {...props} />
      : <Redirect from='*' to='/' />
  )} />
}

export default (
  checkIfLoggedInAndVerified
);


serviceWorker.unregister();