import React from 'react';
import TABLE1 from './Table';

export default class TsTargetDailyStatsTable extends React.Component {

  constructor() {

    super();
    this.state = {
      tsCampaignData: [],
      tsCampaignLink: "",
      tsCampaignDisplay: "",
      tsCampaignDataTableRows: [],
      tsCampaignDataTableColumns: [],
      index: 0,
      columnsExist: false
    }

  }
  componentDidMount() {
    console.log(this.props.tsCampaignDataDailyStat);
    if (this.props.tsCampaignDataDailyStat) {
      var dailyStatRows = [];
      for (let i = 0; i < this.props.tsCampaignDataDailyStat.rows.length; i++) {
        dailyStatRows.push(this.props.tsCampaignDataDailyStat.rows[i]);
      }
      dailyStatRows.push(this.props.tsCampaignDataDailyStat.totals);
      this.setState({
        columnsExist: true,
        col: this.props.tsCampaignDataDailyStat.columns,
        dailyStatRowsOutput: dailyStatRows
      });
    }
  }

  render() {
    return (

      <div style={styleDiv}>
        <div style={stylePadd}>
          <p>Daily Stats of <a href={this.props.tsTarget[0].name.link}>{this.props.tsTarget[0].name.display}</a></p>
          {this.state.columnsExist ?
            <TABLE1 columns={this.state.col} results={this.state.dailyStatRowsOutput} />
            : null}
        </div>
      </div>
    )
  }

}

const styleDiv = {
  backgroundColor: "#d5e6f7",
  marginLeft: 20,
  marginTop: 20
}

const stylePadd = {
  padding: 20
}