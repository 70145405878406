import React from 'react';
import Firebase from './Firebase';
import './App.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import BASE_URL from './constants';

export default class DebounceCredit extends React.Component {

    constructor() {
        super()
        this.state = {
            textVisibility: false,
            prevTime: 1,
            newTime: 1
        }
    }

    loadDbounceCredit() {
        this.setState({
            textVisibility: false
        });

        return Firebase.getUserToken().then(token => {
            return fetch(BASE_URL + 'dashboard/debounceCredit', {
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + token,
                }
            }).then((response) => response.json())
                .then((responseJson) => {
                    var credit;
                    if (responseJson.error !== "") {
                        console.error('Debounce credit - Error: ' + responseJson.error);
                        credit = isNaN(responseJson.credit) ? responseJson.credit : parseInt(responseJson.credit).toLocaleString();
                    } else {
                        credit = isNaN(responseJson.credit) ? responseJson.credit : parseInt(responseJson.credit).toLocaleString();
                    }
                    this.setState({
                        creditOutput: credit,
                        textVisibility: true,
                    }, function () {
                    });
                })
                .catch((error) => {
                    console.error('Debounce credit - Error:: ' + error);
                });
        });
    }

    componentDidMount() {
        Firebase.screenState().then((data) => {
            this.setState({
                debounceCreditValue: data.debounceCredit
            });
            if (this.state.debounceCreditValue && this.state.newTime >= this.state.prevTime) {
                this.loadDbounceCredit();
            }

        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            prevTime: prevState.newTime,
            newTime: nextProps.time,
        };
    }

    render() {
        return (
            <div>
                {this.state.debounceCreditValue ?
                    <div style={styleDebounce}>
                        <p>Debounce credit: {this.state.textVisibility ? <span>{this.state.creditOutput}</span> : <span>Loading...</span>}</p>
                    </div>
                : null}
            </div>
        );

    }

}


const styleDebounce = {
    fontSize: 10,
    marginLeft: 582,
    marginTop: -15
}