import React from 'react';
import './App.css';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IoIosArrowRoundForward } from "react-icons/io";

export default class Breadcrumb extends React.Component {

    render() {

        return (
            <div>
            <div style={styleBredcrumb}>
                {this.props.breadcrumbs.map((value, index) => {
                    var last = false
                    if(this.props.breadcrumbs.length-1 === index){
                        last = true
                    }
                    return(
                        <div key={index}>
                            <a href={value.link}>{value.display}</a>
                            {
                                last ? null
                                : <span>&nbsp;&nbsp;&nbsp;<IoIosArrowRoundForward />&nbsp;&nbsp;&nbsp;</span>
                            }
                        </div>
                    )
                })}
            </div>
            <div style={styleTimeFrame}>
                    (Time-range: from {this.props.fromDateResponse} until {this.props.toDateResponse})
                </div>
                </div>

        );

    }

}

const styleBredcrumb = {
    marginTop: -40,
    marginBottom: 25,
    display:'flex',
    flexDirection:'row',
    fontSize: 12
}

const styleTimeFrame = {
    marginTop: -15,
    marginBottom: 15,
    fontSize: 12
}