import utils from './utils'

// let BASE_URL='https://test-magicformula.herokuapp.com/';




/**
 * For this to work run the following command line in Windows:
 *
 *  set REACT_APP_SERVER_DOMAIN=test-magicformula.herokuapp.com
 *
 *  */
let BASE_URL=`https://${process.env.REACT_APP_SERVER_DOMAIN}/`;
/* */




if(((typeof Storage !== "undefined") && localStorage.getItem('lh'))) BASE_URL='http://localhost:5000/';
utils.debugLog('BASE_URL='+BASE_URL);

export default BASE_URL;
