
// import SIDEBAR from '../Sidebars';
// import Toolbar from 'material-ui/Toolbar';
// import { FaPowerOff } from 'react-icons/fa';
// import ReactTooltip from 'react-tooltip';
import React from 'react';
// import Firebase from '../Firebase';
// import utils from '../utils';
import '../App.css';
// import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
// // import AppBar from 'material-ui/AppBar';
// import TextField from 'material-ui/TextField';
// import Logo from '../logo_app.png';
// import RaisedButton from 'material-ui/RaisedButton';
// import Loader from '../loaderImg.gif';
// import BASE_URL from '../constants';
import Incident, {CLASS_TYPE, ISAMI_Incident, ITrackerCampaign_IdOption, ITsBanner_IdOption, ITsCampaign_IdOption, ITsTarget_IdOption, ITsZone_IdOption} from './Incident';
import IncidentFilters, { ITreeNode } from './IncidentFilters';
import { Container, Row, Col } from 'react-bootstrap';
import { CSSProperties } from 'react';
import { getInfoAbout_TrackerCampaign,
  getInfoAbout_TS,
  getInfoAbout_TsBanner,
  getInfoAbout_TsCampaign,
  getInfoAbout_TsTarget,
  getInfoAbout_TsZone,
  IInfoAboutPerformanceData,
} from 'src/models/infoAboutPerformanceData';

interface ITreeOfIncidents_1stParsing {
  [tsId: string]: {
    incidents: number,
    c: { // children
      [trackerCampaignId: string]: {
        incidents: number,
        c: { // children
          [tsCampaignId: string]: {
            incidents: number,
            bc: { // Banner children
              [tsBannerId: string]: {
                incidents: number,
              },
            },
            tc: { // Target children
              [tsTargetId: string]: {
                incidents: number,
              },
            },
            zc: { // Zone children
              [tsZoneId: string]: {
                incidents: number,
              },
            },
          },
        },
      },
    },
  },
}
interface ICurrentProps {
  incidents: ISAMI_Incident[],
  infoAboutPerformanceData: IInfoAboutPerformanceData,
  hasError: boolean,
}

interface ICurrentState {
  typeOfNode: CLASS_TYPE,
  objectId: string,
  checkedIncidents: string[],
  ignoredIncidents: string[],
}

export default class IncidentList extends React.Component<ICurrentProps, ICurrentState> {

  constructor(props: ICurrentProps) {
    super(props);

    this.state = {
      typeOfNode: CLASS_TYPE.TRACKER,
      objectId: '',
      checkedIncidents: [],
      ignoredIncidents: [],
    }
  }

  updateFilter(selectedNodeId: string) {

    this.setState({
      typeOfNode: (selectedNodeId.split("_")[0] as CLASS_TYPE),
      objectId: selectedNodeId.split("_")[1],
    });
  }

  parseIncidentsIntoTree(originalIncidentList_filtered: ISAMI_Incident[], info: IInfoAboutPerformanceData): ITreeNode {

    const treeOfIncidents_1stParsing: ITreeOfIncidents_1stParsing = {};
    // utils.debugLog('incidentList'+JSON.stringify(incidentList,null,2));
    originalIncidentList_filtered.forEach((currentIncident: ISAMI_Incident) => {
      // utils.debugLog('currentIncident='+JSON.stringify(currentIncident.objectIDs,null,2)+', typeof='+typeof(currentIncident.objectIDs));
      const tsId = currentIncident.objectIDs.tsId;
      const trackerCampaignId: string | null = (currentIncident.objectIDs as ITrackerCampaign_IdOption).trackerCampaignId;
      const tsCampaignId: string | null = (currentIncident.objectIDs as ITsCampaign_IdOption).tsCampaignId;
      const tsBannerId: string | null = (currentIncident.objectIDs as ITsBanner_IdOption).tsBannerId;
      const tsTargetId: string | null = (currentIncident.objectIDs as ITsTarget_IdOption).tsTargetId;
      const tsZoneId: string | null = (currentIncident.objectIDs as ITsZone_IdOption).tsZoneId;

      treeOfIncidents_1stParsing[tsId] = treeOfIncidents_1stParsing[tsId] || {
        incidents: 0,
        c: {}, // children
      };
      treeOfIncidents_1stParsing[tsId].incidents++;

      if(currentIncident.targetType !== CLASS_TYPE.TS) {
        treeOfIncidents_1stParsing[tsId].c[trackerCampaignId] = treeOfIncidents_1stParsing[tsId].c[trackerCampaignId] || {
          incidents: 0,
          c: {}, // children
        };
        treeOfIncidents_1stParsing[tsId].c[trackerCampaignId].incidents++;

        if(currentIncident.targetType !== CLASS_TYPE.TRACKER_CAMPAIGN) {
          treeOfIncidents_1stParsing[tsId].c[trackerCampaignId].c[tsCampaignId] = treeOfIncidents_1stParsing[tsId].c[trackerCampaignId].c[tsCampaignId] || {
            incidents: 0,
            bc: {}, // banner children
            tc: {}, // target children
            zc: {}, // Zone children
          };
          treeOfIncidents_1stParsing[tsId].c[trackerCampaignId].c[tsCampaignId].incidents++;


          if(currentIncident.targetType !== CLASS_TYPE.TS_CAMPAIGN) {
            switch(currentIncident.targetType) {
              case CLASS_TYPE.TS_BANNER:
                treeOfIncidents_1stParsing[tsId].c[trackerCampaignId].c[tsCampaignId].bc[tsBannerId] = treeOfIncidents_1stParsing[tsId].c[trackerCampaignId].c[tsCampaignId].bc[tsBannerId] || {
                  incidents: 0,
                }
                treeOfIncidents_1stParsing[tsId].c[trackerCampaignId].c[tsCampaignId].bc[tsBannerId].incidents++;
                break;

              case CLASS_TYPE.TS_TARGET:
                treeOfIncidents_1stParsing[tsId].c[trackerCampaignId].c[tsCampaignId].tc[tsTargetId] = treeOfIncidents_1stParsing[tsId].c[trackerCampaignId].c[tsCampaignId].tc[tsTargetId] || {
                  incidents: 0,
                }
                treeOfIncidents_1stParsing[tsId].c[trackerCampaignId].c[tsCampaignId].tc[tsTargetId].incidents++;
                break;

              case CLASS_TYPE.TS_ZONE:
                treeOfIncidents_1stParsing[tsId].c[trackerCampaignId].c[tsCampaignId].zc[tsZoneId] = treeOfIncidents_1stParsing[tsId].c[trackerCampaignId].c[tsCampaignId].zc[tsZoneId] || {
                  incidents: 0,
                }
                treeOfIncidents_1stParsing[tsId].c[trackerCampaignId].c[tsCampaignId].zc[tsZoneId].incidents++;
                break;

              default:
                throw new Error(`currentIncident.targetType is not recognized (${currentIncident.targetType})`);
            }
          }
        }
      }
    });

    const treeOfIncidents_2ndParsing: ITreeNode = {
      id: `${CLASS_TYPE.TRACKER}_`,
      name: 'Tracker',
      isOpened: true,
      children: Object.keys(treeOfIncidents_1stParsing).map(tsId => {
        return {
          id: `${CLASS_TYPE.TS}_${tsId}`,
          name: `Traffic Source ${tsId}: ${(getInfoAbout_TS(info, tsId)).n} (${treeOfIncidents_1stParsing[tsId].incidents})`,
          isOpened: true,
          children: Object.keys(treeOfIncidents_1stParsing[tsId].c).map(trackerCampaignId => {
            return {
              id: `${CLASS_TYPE.TRACKER_CAMPAIGN}_${trackerCampaignId}`,
              name: `Tracker Campaign ${trackerCampaignId}: ${(getInfoAbout_TrackerCampaign(info, tsId, trackerCampaignId)).n} (${treeOfIncidents_1stParsing[tsId].c[trackerCampaignId].incidents})`,
              isOpened: true,
              children: Object.keys(treeOfIncidents_1stParsing[tsId].c[trackerCampaignId].c).map(tsCampaignId => {
                const currentTsCampaign = treeOfIncidents_1stParsing[tsId].c[trackerCampaignId].c[tsCampaignId];
                // console.log('currentTsCampaign='+JSON.stringify(currentTsCampaign,null,2));

                const _parsedID = `${CLASS_TYPE.TS_CAMPAIGN}_${tsCampaignId}`;
                const _parsedName = `TS Campaign ${tsCampaignId}: ${(getInfoAbout_TsCampaign(info, tsId, trackerCampaignId, tsCampaignId)).n} (${currentTsCampaign.incidents})`;
                const _children = [];
                if(Object.keys(currentTsCampaign.bc).length) {
                  _children.push({

                    id: `${CLASS_TYPE.tsBannerList}_${tsCampaignId}`,
                    name: `Banners`,
                    isOpened: true,
                    children: Object.keys(currentTsCampaign.bc).map(tsBannerId =>{
                      return {
                        id: `${CLASS_TYPE.TS_BANNER}_${tsBannerId}`,
                        name: `Banner ${tsBannerId}: ${(getInfoAbout_TsBanner(info, tsId, trackerCampaignId, tsCampaignId, tsBannerId)).n} (${currentTsCampaign.bc[tsBannerId].incidents})`,
                        children: [],
                      };
                    }),
                  });
                }
                if(Object.keys(currentTsCampaign.tc).length) {
                  _children.push({
                    id: `${CLASS_TYPE.tsTargetList}_${tsCampaignId}`,
                    name: `Targets`,
                    isOpened: true,
                    children: Object.keys(currentTsCampaign.tc).map(tsTargetId =>{
                      return {
                        id: `${CLASS_TYPE.TS_TARGET}_${tsTargetId}`,
                        name: `Target ${tsTargetId}: ${(getInfoAbout_TsTarget(info, tsId, trackerCampaignId, tsCampaignId, tsTargetId)).n} (${currentTsCampaign.tc[tsTargetId].incidents})`,
                        children: [],
                      };
                    }),
                  });
                }
                if(Object.keys(currentTsCampaign.zc).length) {
                  _children.push({

                    id: `${CLASS_TYPE.tsZoneList}_${tsCampaignId}`,
                    name: `Zones`,
                    isOpened: false,
                    children: Object.keys(currentTsCampaign.zc).map(tsZoneId =>{
                      return {
                        id: `${CLASS_TYPE.TS_ZONE}_${tsZoneId}`,
                        name: `Zone ${tsZoneId}: ${(getInfoAbout_TsZone(info, tsId, trackerCampaignId, tsCampaignId, tsZoneId)).n} (${currentTsCampaign.zc[tsZoneId].incidents})`,
                        children: [],
                      };
                    }),
                  });
                }
                return {
                  id: _parsedID,
                  name: _parsedName,
                  isOpened: true,
                  children: _children,
                };
              }),
            };
          }),
        };
      }),
    };

    return treeOfIncidents_2ndParsing;
  }

  getOriginalIncidentList_filtered() {
    return this.props.incidents.filter((incident: ISAMI_Incident) => (!this.state.ignoredIncidents.includes(incident._id))) || [];
  }

  render() {

    const originalIncidentList = this.getOriginalIncidentList_filtered();

    const treeNode: ITreeNode = this.parseIncidentsIntoTree(originalIncidentList, this.props.infoAboutPerformanceData);
    let parsedIncidentList: ISAMI_Incident[] = [];
    if(this.state.typeOfNode === CLASS_TYPE.TRACKER) {
      parsedIncidentList = originalIncidentList;
    } else {
      originalIncidentList.forEach(currentIncident => {
        let idToMatch;
        switch(this.state.typeOfNode) {

          case CLASS_TYPE.TS:
            idToMatch = currentIncident.objectIDs.tsId;
            break;

          case CLASS_TYPE.TRACKER_CAMPAIGN:
            idToMatch = (currentIncident.objectIDs as ITrackerCampaign_IdOption).trackerCampaignId;
            break;

          case CLASS_TYPE.TS_CAMPAIGN:
            idToMatch = (currentIncident.objectIDs as ITsCampaign_IdOption).tsCampaignId;
            break;

          case CLASS_TYPE.TS_BANNER:
            idToMatch = (currentIncident.objectIDs as ITsBanner_IdOption).tsBannerId;
            break;

          case CLASS_TYPE.TS_TARGET:
            idToMatch = (currentIncident.objectIDs as ITsTarget_IdOption).tsTargetId;
            break;

          case CLASS_TYPE.TS_ZONE:
            idToMatch = (currentIncident.objectIDs as ITsZone_IdOption).tsZoneId;
            break;

          case CLASS_TYPE.tsBannerList:
            idToMatch = currentIncident.targetType === CLASS_TYPE.TS_BANNER ? (currentIncident.objectIDs as ITsBanner_IdOption).tsCampaignId : '';
            break;

          case CLASS_TYPE.tsTargetList:
            idToMatch = currentIncident.targetType === CLASS_TYPE.TS_TARGET ? (currentIncident.objectIDs as ITsTarget_IdOption).tsCampaignId : '';
            break;

          case CLASS_TYPE.tsZoneList:
            idToMatch = currentIncident.targetType === CLASS_TYPE.TS_ZONE ? (currentIncident.objectIDs as ITsZone_IdOption).tsCampaignId : '';
            break;

          default:
            console.error('this.state.typeOfNode is unknown: '+this.state.typeOfNode);
        }
        if(idToMatch === this.state.objectId) {
          parsedIncidentList.push(currentIncident);
        }
      });
    }

    return (
      <Container>
        <Row>
          <Col md={4}>
            <IncidentFilters
              treeOfIncidents={treeNode}
              updateFilter={this.updateFilter.bind(this)}
            />
          </Col>
          <Col md={8}>
            <div style={listWrapper}>
              Number of incidents: {this.props.hasError ?
                "Error!" :
                ((parsedIncidentList.length !== originalIncidentList.length) ? `${parsedIncidentList.length} out of ${originalIncidentList.length}` : `${originalIncidentList.length}`)}
              {parsedIncidentList.map((incident, idx)=>
                <Incident
                  key={idx}
                  incident={incident}
                  infoAboutPerformanceData={this.props.infoAboutPerformanceData}
                  updateArchivedincidents={(incidents)=>{
                    this.setState({
                      ignoredIncidents: [
                        ...this.state.ignoredIncidents,
                        ...incidents,
                      ],
                    })
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

const listWrapper: CSSProperties = {
  // width: '500px',
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
}

// const styleLogoImg = {
//   width: 160,
//   height: '100%'
// }

// const styleRefresh = {
//   marginTop: 25,
//   marginRight: 0,
//   cursor: 'pointer',
//   zIndex: "999"
// }
// const styleTextArea = {
//   width: '100%',
//   height: '100%',
//   border: '0px solid #ccc',
//   paddingLeft:10,
//   paddingBottom:10
// }

// const styleToolbar = {
//   marginTop: -30
// }

// const styleTextAreaOutside = {
//   padding:10
// }

// const styleInputCampaignId = {
//   marginRight: 10,
// }

// const styleLoaderImg = {
//   width: 70,
//   height: 70,
//   // marginLeft: 550
// }
