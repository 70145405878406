import React from 'react';
import TABLE1 from './Table';
import TsTargetDailyStats from './TsTargetDailyStatsTable';

export default class TsTarget extends React.Component {


  constructor() {

    super();
    this.state = {
      tsCampaignData: [],
      tsCampaignLink: "",
      tsCampaignDisplay: "",
      tsCampaignDataTableRows: [],
      tsCampaignDataTableColumns: [],
      index: 0
    }

  }
  componentDidMount() {
    this.setState({
      index: this.props.index
    });

  }


  render() {
    var ro = [];
    ro.push(this.props.tsTargetFull.rows[this.state.index]);
    delete ro[0].childrenDailyStats;

    return (
      <div style={styleDiv}>
        <div style={stylePadd}>
          <a href={this.props.tsTargetData.name.link}>{this.props.tsTargetData.name.display}</a>
          <div>
            <TABLE1 columns={this.props.tsTargetFull.columns} results={ro} />
          </div>
          <TsTargetDailyStats tsCampaignDataDailyStat={this.props.tsTargetDailyStat} tsTarget={ro} />
        </div>
      </div>

    )
  }

}

const styleDiv = {
  backgroundColor: "#e4eaf0",
  marginLeft: 20,
  marginTop: 20
}

const stylePadd = {
  padding: 20
}