import React from 'react';
import Firebase from './Firebase';
import utils from './utils';
import './App.css';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import AppBar from 'material-ui/AppBar';
import TextField from 'material-ui/TextField';
import Logo from './logo_app.png';
import RaisedButton from 'material-ui/RaisedButton';


export default class Login extends React.Component {



  constructor() {

    super()

    this.state = {
      username: '',
      password: '',
      errorMsg: '',
      disabledButton: false
    }

  }

  componentDidMount() {

    if (Firebase.isLoggedIn() && Firebase.getCurrentUser().emailVerified) {
      // console.log(Firebase.getCurrentUser().emailVerified);
      this.props.history.push("/");
    } else if (this.props.isLoggedInButNotVerified) {
      let lastEmailVerification = utils.getCache('lastEmailVerification', 5 * 60 * 1000);

      if (lastEmailVerification && !lastEmailVerification.isExpired) {
        this.setState({ errorMsg: 'Email address has not been verified! (last verification email was sent on ' + lastEmailVerification.timestamp.toLocaleString() + ')' });
      } else {
        Firebase.resendEmailVerification().then(() => {
          utils.setCache('lastEmailVerification', new Date());
          this.setState({ errorMsg: 'Email address has not been verified! (we\'ve just re-sent another verification email, please check your inbox or spam folder)' });
        }).catch(() => {
          // Do nothing.
        });
      }
    }

  }

  handleClick(event) {
    if (event.key === 'Enter' || event.type === 'click') {
      this.setState({
        errorMsg: '',
        disabledButton: true
      });
      if (this.state.username === '' || this.state.password === '') {
        this.setState({
          errorMsg: "Username or Password cannot be empty!",
          disabledButton: false
        });
        event.preventDefault();
        return;
      }
      Firebase.login(this.state.username, this.state.password)
        .then(
          () => {
            this.props.history.push("/");
          }
        )
        .catch(error => {
          this.setState({
            errorMsg: error.message,
            disabledButton: false
          });
        });
    }
  }

  render() {


    return (
      <div style={{ textAlign: 'center' }}>
        <MuiThemeProvider>
          <div>
            <AppBar
              title="Login"
            />
            <br />
            <br />
            <br />
            <br />
            <br />
            <img src={Logo} style={styleLogoImg} alt="logo"></img>
            <br />
            <TextField
              hintText="Enter your Email"
              floatingLabelText="Email"
              onChange={(event, newValue) => this.setState({ username: newValue, errorMsg: '' })}
              onKeyDown={(event) => this.handleClick(event)}
            />
            <br />
            <TextField
              type="password"
              hintText="Enter your Password"
              floatingLabelText="Password"
              onChange={(event, newValue) => this.setState({ password: newValue, errorMsg: '' })}
              onKeyDown={(event) => this.handleClick(event)}
            />
            <br />
            <br />
            <RaisedButton label="Submit" disabled={this.state.disabledButton} primary={true} onClick={(event) => this.handleClick(event)} />
            <div style={{ color: 'red' }}>{this.state.errorMsg}</div>
          </div>
        </MuiThemeProvider>
      </div>
    );
  }
}

const styleLogoImg = {
  width: 200,
  height: 100,
};